#contactEdit {
    height: fit-content;
    padding: 0px 18px;
    margin-top: 81px;
}

#contactEdit h3 {
    color: darkred;
    text-shadow: 2px 1px #3b0600;
    margin: 22px 0px 33px 0px;
    font-size: 33px;
    text-align: center;
}

#contactEdit form {
    margin: auto;
    padding: 66px;
    max-width: 666px;
    margin-bottom: 100px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.3rem;
    border: 4px solid rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 735px) {
    #contactEdit form {
        padding: 33px 22px;
    }
}


#contactEdit label {
    color: darkred;
    font-size: 27px;
}

#contactEdit .bi {
    margin-left: 5px;
}

@media screen and (max-width: 400px) {
    #contactEdit label {
        font-size: 24px;
    }
}

#contactEdit .form-control {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
}

#contactEdit button {
    margin: 27px auto;
    width: 50%;
    min-width: 250px;
}