html,
body {
  font-family: 'Sancreek';
  font-size: 22px;
  background-color: #272727;
}

/* FADE IN EFFECT */

.pace-running .fadeIn > *:not(.pace) {
  opacity: 0;
}

.pace-done .fadeIn > *:not(.pace) {
  opacity: 1;
  animation: FadeAnim 1.5s ease;
}

/* .fadeIn {
  animation: FadeAnim 3s ease-in;
} */

@keyframes FadeAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*GENERAL*/

.red {
  color: darkred;
}

h5 {
  margin: 16px;
}

.nav {
  z-index: 100;
}

.btn-danger {
  background-color: darkred;
}

.btn-danger:hover {
  background-color: black;
}

.nav-link {
  color: white;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
}

p a:hover {
  text-decoration: underline;
}

.form-control {
  border: 2px solid rgba(425, 425, 425, 0.1);
}

.form-control:focus {
  border-color: rgba(139, 0, 0, 0.4);
  box-shadow: none;
  -webkit-box-shadow: none;
}

.has-error .form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

hr {
  background-color: black;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid darkred;
  color: darkred;
}

.modal-body {
  color: white;
}

/* HOVER CSS */

#menu .hvr-sink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
#menu .hvr-sink:hover,
.hvr-sink:focus,
.hvr-sink:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.addButton.btn {
  margin: 27px auto;
  max-width: 420px;
}
