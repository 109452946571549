#contact {
  padding: 17px;
}

#contact .jumbotron {
  margin-top: 33px;
  margin-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  padding: 4rem 2rem;
  border: 4px solid rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 576px) {
  #contact .jumbotron {
    padding: 2rem 1rem;
  }
}

#contact h5 {
  color: darkred;
  text-shadow: 2px 1px black;
}

#contact a {
  color: darkred;
  text-shadow: 2px 1px black;
}

#contact a:hover {
  text-decoration: underline;
}

#contact hr {
  background-color: white;
}

.socmed.contact img {
  width: 55px;
  margin-right: 5px;
  margin-bottom: 13px;
  border-radius: 13px;
  background-color: rgba(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.socmed.contact img:hover {
  background-color: darkred;
}

#contact .sendmsg {
  margin: auto;
  padding-bottom: 33px;
}

#contact .form-group {
  color: darkred;
  text-shadow: 1px 1px black;
  margin-bottom: 1rem;
}

#contact label {
  margin-bottom: 0.5rem;
}

#contact .form-control {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

#contact .form-control:focus {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}
