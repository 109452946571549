#videoEdit {
    display: flex;
    flex-direction: column;
    margin: 33px auto;
}

#videoEdit h3 {
    color: darkred;
    text-shadow: 2px 1px #3b0600;
    margin-top: 33px;
    font-size: 33px;
    text-align: center;
}

#videoEdit hr {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 44px;
}

#videoEdit .currentVideos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(318px, 1fr));
    grid-gap: 27px;
    margin-bottom: 15px;
    padding: 33px 0px;
}

@media screen and (max-width: 360px) {
    #videoEdit .currentVideos {
        grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
    }
}

.vid-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 666px;
    margin: auto;
    padding: 17px 17px 7px 17px;
    background-color: rgba(0,0,0,0.4);
    border-radius: 7px;
}

.currentVideos .video {
    height: 327px;
    width: 100%;
}

.video iframe {
    height: 100%;
    width: 100%;
}

.vid-container .buttons {
    padding: 13px 0px;
    width: 100%;
}

.buttons .bi {
    margin-right: 7px;
}

.noVids {
    text-align: center;
    color: darkred;
    margin: 15vh auto 27vh;
}

#videoEdit .see-more {
    width: 100%;
    max-width: 666px;
    margin: 30px auto 45px;
    padding: 0px 27px;
}
