#mediaEdit .main.nav {
    position: sticky;
    z-index: 1;
    top: 95.5px;
    overflow: hidden;
  }
  
  @media only screen and (max-width: 991px) {
    #mediaEdit .main.nav {
      top: 87px;
    }
  }
  
  #mediaEdit .videos-tab {
    margin-top: 27px;
    color: white;
  }
  
  .videos-tab .nav-link.select {
    color: darkred;
  }
 
  
  #mediaEdit .main {
    border-bottom: 1px solid white;
    background-color: rgba(0, 0, 0, 0.8);
    position: sticky;
    z-index: 1;
    top: 95.5px;
    box-shadow: 0px -1px 13px black;
    font-size: 27.5px;
  }
  
  #mediaEdit .nav-link.active {
    border-color: transparent;
    background-color: transparent;
    color: darkred;
    text-shadow: 2px 0.5px black;
  }
  #mediaEdit .nav-link:hover {
    border-color: transparent;
    background-color: transparent;
    color: darkred;
    text-shadow: 2px 0.5px black;
  }
