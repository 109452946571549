.react-datepicker {
  text-shadow: none;
  border: 4px solid rgba(0, 0, 0, 0.8);
  border-radius: 0.5rem;
}

.react-datepicker__input-container input {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: 2px solid rgba(425, 425, 425, 0.1);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__input-container input:focus {
  border-color: rgba(139, 0, 0, 0.4);
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none;
}

.react-datepicker__header {
  background-color: rgba(0, 0, 0, 0.9);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: darkred;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: white;
}

react-datepicker__month .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: black;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: darkred;
  color: white;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: darkred;
  color: white;
}

.react-datepicker__day--selected:hover {
  background-color: black;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: black;
}

/* TIME FIELD CSS */

.react-datepicker__time-container {
  width: fit-content;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: fit-content;
}

.react-datepicker__time-container .react-datepicker__time {
  width: fit-content;
}

.react-datepicker--time-only .react-datepicker__time-container {
  width: fit-content;
}
