/* .final-form {
  max-width: 450px;
  margin: 27px auto;
} */

.final-form .form-group {
  color: darkred;
  text-shadow: 1px 1px black;
  margin-bottom: 1rem;
}

.final-form label {
  margin-bottom: 0.5rem;
}

.final-form .form-control {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

.final-form .form-control:focus {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

.final-form input.form-control {
  width: 100%;
  margin-left: 0px;
}

.submitForm {
  margin: 20px 0px;
}

.final-form .error {
  background-color: rgb(51, 5, 0, 0.5);
}

.final-form .input-group {
  flex-wrap: nowrap;
}

.final-form .input-group-text#basic-addon1 {
  font-size: 25px;
  color: darkred;
  background-color: rgba(0, 0, 0, 1);
  border: 2px solid #272727;
  border-radius: 3px;
}

.final-form .cancel {
  margin-bottom: 50px;
}

.final-form .modal-body {
  max-width: 427px;
}

.final-form .modal-footer {
  justify-content: center;
}

@media screen and (max-width: 352px) {
  .input-group-text {
    padding: 0.375rem 0.35rem;
  }
}
